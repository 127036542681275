import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AccountDetail from "../components/accounts/accountDetail";
import Container from "@material-ui/core/Container";

import { useLocation } from '@reach/router';

const AccountDetailPage = () => {
  let path = useLocation();
  let accountId = path.search.split('?id=')[1];

  return (
    <Layout>
      <Seo title="Service Provider" />
      <div style={{ paddingBottom: '100px' }}>
        <Container maxWidth="sm" style={{ paddingTop: '10px' }}>
          <AccountDetail id={accountId} />
        </Container>
      </div>
    </Layout>
  )
}

export default AccountDetailPage
